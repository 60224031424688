import { axiosInstanceProxy } from "./useAxiosClient/index"
import axios from "axios"
const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_PROXY_URL,
})

const loginToken = () => {
  if (typeof window !== "undefined") return axiosInstanceProxy.post("/token/")
  return {}
}

axiosInstance.interceptors.request.use(
  config => {
    const token =
      typeof window !== "undefined" ? localStorage.getItem("token") : ""
    if (token) config.headers["Authorization"] = "Bearer " + token // for Spring Boot back-end
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config
    if (err.response && err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true
      try {
        const response = await loginToken()
        localStorage.setItem("token", response?.data?.access)
        return axios(originalConfig)
      } catch (_error) {
        return Promise.reject(_error)
      }
    }
    return Promise.reject(err)
  }
)

export default axiosInstance
