// eslint-disable-next-line no-unused-vars
import useAxios, { Options } from "axios-hooks"
// eslint-disable-next-line no-unused-vars
import axios, { AxiosRequestConfig } from "axios"
const axiosInstanceProxy = axios.create({
  baseURL: process.env.GATSBY_PROXY_URL,
})
const loginToken = () => {
  if (typeof window !== "undefined") return axiosInstanceProxy.post("/token/")
  return {}
}

/**
 *
 * @param {AxiosRequestConfig} config
 * @param {Options} options
 */

export function useAxiosClient(config, options) {
  const isBrowser = typeof window !== "undefined"
  const newOptions = isBrowser ? options : { manual: true }

  axios.interceptors.request.use(
    config => {
      try {
        const token = isBrowser ? localStorage.getItem("token") : ""
        if (token) config.headers["Authorization"] = "Bearer " + token // for Spring Boot back-end
        return config
      } catch (_error) {
        return Promise.reject(_error)
      }
    },
    error => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    res => {
      return res
    },
    async err => {
      const originalConfig = err?.config
      try {
        if (
          err?.response &&
          err.response.status === 401 &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true
          const response = await loginToken()
          if (isBrowser) localStorage.setItem("token", response?.data?.access)
          return axios(originalConfig)
        }
        return Promise.reject(err)
      } catch (_error) {
        return Promise.reject(_error)
      }
    }
  )

  const hookRes = useAxios(
    {
      method: "get", // default
      ...config,
      baseURL: process.env.GATSBY_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    },
    newOptions
  )

  return isBrowser ? hookRes : [{}]
}

export default axiosInstanceProxy
