import fetcher from "../service/axiosConfig"

const STORE_ID = "10"

class DataCollect {
  constructor() {
    this.sessionId = null
    this.fetcher = fetcher
    this.store = STORE_ID
  }

  init() {
    const sessionId = this.getLocalSessionId()

    const hasSession = !!sessionId

    return hasSession ? this.setSessionId(sessionId) : this.getNewSessionId()
  }

  async send(location) {
    const sessionId = this.sessionId ? this.sessionId : this.getLocalSessionId()
    const { pathname, search } = location
    const ip = await this.getUserIP()
    const body = {
      session_id: sessionId,
      request_path: pathname,
      request_uri: pathname + search,
      store: this.store,
      user_ip: ip,
      origin: 1
    }
    this.fetcher({ url: "/datacollect/", data: body, method: "POST" })
  }

  async getUserIP() {
    const ip = await fetch("https://api64.ipify.org?format=json/").then(res =>
      res.text()
    )
    return ip
  }

  setSessionId(id) {
    this.sessionId = id
  }

  async getNewSessionId() {
    this.fetcher({ url: "/datacollect/uuid/" }).then(({ session_id }) => {
      this.setSessionId(session_id)
      localStorage.setItem("session_id", session_id)
    })
  }

  getLocalSessionId() {
    return localStorage.getItem("session_id")
  }

  getSessionId() {
    return this.sessionId
  }
}

export default new DataCollect()
